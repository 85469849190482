const textVariables = require('./textVariables');

const channelConfig = {
  id: 'portal',
  name: 'Audax Portaal',
  isB2B: true,
  brand: 'portal',
  locale: 'nl-nl',
  gtm: 'GTM-PNBHB3R',
  clangOrigin: 'e.audaxportaal.nl',
  sitemap: '/waroot/sitemap-audaxportal_nl.xml',
  imageBaseURL: 'https://api.audaxportaal.nl',
  devProxy: {
    base: 'http://localhost:3000',
    images: 'https://devapi.audaxportaal.nl',
  },
  api: {
    be: 'https://local.bruna.nl',
    local: 'https://testapi.audaxportaal.nl',
    dev: 'https://devapi.audaxportaal.nl',
    devstag: 'https://devstagapi.audaxportaal.nl',
    test: 'https://testapi.audaxportaal.nl',
    teststag: 'https://teststagapi.audaxportaal.nl',
    acc: 'https://accapi.audaxportaal.nl',
    accstag: 'https://accstagapi.audaxportaal.nl',
    prd: 'https://api.audaxportaal.nl',
    www: 'https://api.audaxportaal.nl',
    prdstag: 'https://prdstagapi.audaxportaal.nl',
  },
  intershopId: 'tba-audaxportal_nl-Site',
  footer: {
    legalLinks: [
      { label: 'Algemene voorwaarden', url: 'https://klantenservice.audaxportaal.nl' },
      { label: 'Privacy', url: 'https://klantenservice.audaxportaal.nl' },
      { label: 'Cookies', url: '/cookiebeleid' },
      { label: 'Disclaimer', url: 'https://klantenservice.audaxportaal.nl' },
    ],
    icons: [
      { key: 'ideal', url: '/betalen' },
      { key: 'mastercard', url: '/betalen' },
      { key: 'visa', url: '/betalen' },
    ],
  },
  header: {
    components: ['accountBar', 'notificationBar', 'masthead', 'navigationBar', 'communicationBar', 'saleBar'],
    hasMemberships: true,
    wishListUrl: '/mijn-account/wishlists',
    checkoutUrl: '/winkelmand',
    accountUrl: '/mijn-account',
    loginUrl: '/inloggen',
    accountMenu: [
      {
        id: 'overzicht',
        label: 'common.home.link',
        url: '/mijn-account',
        ignoreInOverview: true,
      }, {
        id: 'gegevens',
        label: 'myenv.mainhub.profile-title',
        description: 'myenv.mainhub.profile-desc',
        link: 'myenv.mainhub.profile-linktext',
      }, {
        id: 'orders',
        label: 'myenv.mainhub.orders-title',
        description: 'myenv.mainhub.orders-desc',
        link: 'myenv.mainhub.orders-linktext',
      }, {
        id: 'leveringen',
        label: 'myenv.mainhub.shipment-title',
        description: 'myenv.mainhub.shipment-desc',
        link: 'myenv.mainhub.shipment-linktext',
      }, {
        id: 'retour',
        label: 'myenv.mainhub.returns-title',
        description: 'myenv.mainhub.returns-desc',
        link: 'myenv.mainhub.returns-linktext',
      }, {
        id: 'manco',
        label: 'myenv.mainhub.manco-title',
        description: 'myenv.mainhub.manco-desc',
        link: 'myenv.mainhub.manco-linktext',
      }, {
        id: 'wishlists',
        label: 'myenv.mainhub.wishlist-title',
        description: 'myenv.mainhub.wishlist-desc',
        link: 'myenv.mainhub.wishlist-linktext',
      }, {
        id: 'facturen',
        label: 'myenv.mainhub.invoices-title',
        description: 'myenv.mainhub.invoices-desc',
        link: 'myenv.mainhub.invoices-linktext',
      }, {
        id: 'bulk-bestelling',
        label: 'myenv.mainhub.bulk-order-title',
        description: 'myenv.mainhub.bulk-order-desc',
        link: 'myenv.mainhub.bulk-order-linktext',
      }, {
        id: 'intekeningen',
        label: 'myenv.mainhub.signups-title',
        description: 'myenv.mainhub.signups-desc',
        link: 'myenv.mainhub.signups-linktext',
        hidden: true,
      }, {
        id: 'overige-zaken',
        label: 'myenv.mainhub.miscellaneous-title',
        description: 'myenv.mainhub.miscellaneous-desc',
        link: 'myenv.mainhub.miscellaneous-linktext',
      },
    ],
    rating: {
      api: 'https://www.kiyoh.com/v1/review/feed.json?hash=joiu0fup3eww5hh&limit=1',
      responseAttribute: 'averageRating',
    },
    usp: {
      regular: [
        {
          icon: 'ico-check',
          text: 'header.usp1.text',
          link: 'header.usp1.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp2.text',
          link: 'header.usp2.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp3.text',
          link: 'header.usp3.link',
        },
      ],
    },
  },
  features: {
    shippingCosts: {
      active: false,
    },
    allowGiftcards: {
      active: false,
    },
    stockCheck: {
      active: false,
    },
    clickAndCollect: {
      active: false,
      data: {
        prefixLength: 4,
      },
    },
    reviewRating: {
      active: false,
    },
    usp: {
      active: true,
      data: {
        regular: [
          {
            icon: 'ico-usp-generic',
            text: 'membership.usp1.standard',
            link: 'membership.usp1.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp2.standard',
            link: 'membership.usp2.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp3.standard',
            link: 'membership.usp3.standard.link',
          },
        ],
        hasSecondaryLink: true,
      },
    },
    polly: {
      active: false,
      data: {
        id: 'EzowauGYhQvhYT3fX',
      },
    },
    storePickup: {
      active: false,
    },
    priceLabel: {
      active: true,
    },
    membership: {
      active: false,
    },
    balanceChecker: {
      active: false,
    },
  },
  urls: {
    myAccount: '/mijn-account',
    ebooks: '/ebooks',
    signups: '/mijn-account/intekeningen',
    customerService: 'https://klantenservice.audaxportaal.nl/',
  },
  themeColors: {
    defaultCatHeaderBGColor: '#e7e7e7',
  },
  trackAndTraceUrl: 'https://jouw.postnl.nl/?CountryIso=NL#!/track-en-trace/[tracecode]/NL/[zipcode]',
  priceNotationLocale: 'nl-NL',
  iconMap: {
    'ico-usp-membership': 'ico-arrow-circle-right',
    'ico-usp-generic': 'ico-check',
    'ico-check-modern': 'ico-check',
    'ico-wishlist-header': 'ico-heart-outline',
    'ico-wishlist-product-add': 'ico-heart-outline',
    'ico-wishlist-product-added': 'ico-wishlist-added',
    'ico-user-header': 'ico-user-alt',
    'ico-cart': 'ico-shopping-bag',
    'ico-rating': 'ico-star',
  },
  seo: {
    host: 'https://www.audaxportaal.nl',
    defaultTitle: 'Het portaal voor al uw producten',
    defaultDescription: 'Het Audax Portaal is het portaal waar je al je bestellingen en leveringen kunt zien.',
    defaultImagePath: 'https://www.audaxportaal.nl/images/newsletter/social-audaxportaal.png',
    appendix: '| Audax Portaal',
    descriptionAppendix: '',
    alternates: [],
    fbAppId: '',
    twitterHandle: '',
    organization: {
      name: 'Audax Portaal',
      description: 'Het Audax Portaal is het portaal waar je al je bestellingen en leveringen kunt zien.',
      url: 'https://www.audaxportaal.nl',
      areaServed: 'NL',
      telephone: '+31881338650',
      logo: 'https://www.audaxportaal.nl/client/assets/organization-logo.png',
      sameAs: [
        'https://www.linkedin.com/company/van-ditmar/',
        'https://www.instagram.com/vanditmarbooks/',
      ],
      email: 'sales@vanditmar.nl',
    },
  },
  textVariables,
  adyen: {
    test: 'n/a',
    live: 'n/a',
  },
  checkout: {
    paymentMethodGiftcard: 'n/a',
  },
};

module.exports = channelConfig;
