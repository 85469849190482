const textVariables = {
  channelName: 'Audax Portaal',
  phoneNumber: '0881338650',
  phoneNumberDisplay: '088-133 8650',
  facebookProfileUrl: '',
  twitterProfileUrl: '',
  whatsappUrl: '',
  kiyohUrl: '',
  linkedinUrl: 'https://www.linkedin.com/company/van-ditmar/',
  instagramUrl: 'https://www.instagram.com/vanditmarbooks/',

  // Customer service urls
  customerServiceUrl: 'https://klantenservice.audaxportaal.nl',
  csLinkToB2B: 'mailto:sales@vanditmar.nl',
  csEmailAddress: 'sales@vanditmar.nl',
  csLinkToMembership: 'https://klantenservice.audaxportaal.nl',
  csLinkToContact: 'https://klantenservice.audaxportaal.nl/contact',
  csLinkToEbooks: 'https://klantenservice.audaxportaal.nl',
  csLinkToFaq: 'https://klantenservice.audaxportaal.nl',
};

module.exports = textVariables;
